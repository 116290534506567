import React from 'react';
import {CLICK} from 'wix-ui-core/dist/src/components/dropdown/constants';
import {Dropdown} from 'wix-ui-core/dropdown';
import {IProductOptionsItem, IProductOptionSelectionItem} from '../../../types/productDef';
import {getDropdownOptionsFromSelections} from './OptionsDropdownUtils';
import {withTranslations, IProvidedTranslationProps} from '../../../providers/globalPropsProvider';
import {OptionsArrow} from '../../../icons/dist/components/OptionsArrow';
import * as _ from 'lodash';

import st from './OptionsDropdown.st.css';
import s from './OptionsDropdown.scss';
import {Option} from 'wix-ui-core/dropdown-option';

export interface OptionsDropdownProps extends IProvidedTranslationProps {
  dropdown: IProductOptionsItem;
  onSelect(selected: IProductOptionSelectionItem[]): void;
  selected?: IProductOptionSelectionItem;
}

@withTranslations
export class OptionsDropdown extends React.Component<OptionsDropdownProps> {
  private readonly onSelect = (nextSelected: Option) => {
    const {onSelect, selected: currentSelected} = this.props;
    const selected = (currentSelected && currentSelected.id) === nextSelected.id ? null : nextSelected;
    const selection = selected
      ? (_.pick(selected, ['id', 'description', 'value', 'linkedMediaItems']) as IProductOptionSelectionItem)
      : null;

    onSelect([selection]);
  };

  private renderDropdownButton(): JSX.Element {
    const {t, selected} = this.props;

    return (
      <div data-hook="options-dropdown-button" className={s.dropDown} tabIndex={0}>
        <span>{selected ? selected.description : t('SELECT_PLACE_HOLDER')}</span>
        <OptionsArrow />
      </div>
    );
  }

  public render() {
    const {
      selected,
      dropdown: {title, selections},
    } = this.props;

    const options = getDropdownOptionsFromSelections(selections);

    return (
      <div>
        <div data-hook="options-dropdown-title" className={s.title}>
          {title}
        </div>

        <div {...st('root')}>
          <Dropdown
            {...st('dropdown')}
            options={options}
            onSelect={this.onSelect}
            openTrigger={CLICK}
            allowReselect={true}
            initialSelectedIds={selected ? [selected.id] : []}>
            {this.renderDropdownButton()}
          </Dropdown>
        </div>
      </div>
    );
  }
}
