import {IProduct, IProductOptionsItem, IOptionSelectionVariant} from '../../types/productDef';
import {ProductOptionType, ProductInventoryStatus} from '../../constants';
import {UserInput, UserInputErrors} from '../../types/app-types';
import {QuantityCalculator} from './quantityCalculator';
import {last, first} from '../../commons/utils';
import {IProductCustomTextAnswer} from '@wix/wixstores-client-core/dist/es/src/types/product';

export function selectProductOptionItems(product: IProduct): IProductOptionsItem[] {
  return product.options;
}

export function selectProductOptionItemsByType(product: IProduct, type: ProductOptionType): IProductOptionsItem {
  return product.options.find(o => o.optionType === type);
}

export function inStock(product: IProduct, variant?: IOptionSelectionVariant) {
  const ref = variant || product;

  if (product.isInStock === false) {
    return false;
  }

  if (product.isTrackingInventory) {
    if (variant) {
      return ref.inventory.quantity > 0;
    }
    return product.inventory.quantity > 0 || product.productItems.some(item => item.inventory.quantity > 0);
  }

  return ref.inventory.status === ProductInventoryStatus.IN_STOCK;
}

export function userInputsFactory(): UserInput | UserInputErrors {
  return {selection: [], text: [], quantity: []};
}

export function validateUserInputs(product: IProduct, userInputs: UserInput): UserInputErrors {
  const errors = userInputsFactory() as UserInputErrors;
  const quantityRange = QuantityCalculator.getQuantitiesRange(product, userInputs.selection);
  const userQuantity = Number(userInputs.quantity[0]);

  product.options.forEach((_, i) => {
    errors.selection[i] = !userInputs.selection[i];
  });

  if (product.customTextFields) {
    product.customTextFields.forEach((item, i) => {
      errors.text[i] = item.isMandatory === true && !userInputs.text[i];
    });
  }

  errors.quantity = [
    (isNaN(userQuantity) || userQuantity < first(quantityRange) || userQuantity > last(quantityRange)) === true,
  ];

  return errors;
}

export function formatCustomTextFields(product: IProduct, userInputs: UserInput): IProductCustomTextAnswer[] {
  const customTextFields: IProductCustomTextAnswer[] = [];

  return (product.customTextFields || []).reduce((acc, field, i) => {
    acc.push({
      customText: {title: field.title},
      answer: userInputs.text[i] || '',
    });

    return acc;
  }, customTextFields);
}

export function hasVariantInStock(product: IProduct): boolean {
  return product.productItems.some((option: IOptionSelectionVariant) => inStock(product, option));
}
