import {IProductAdditionalInfo} from '../../types/productDef';
import {ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import {InfoSectionLayoutId, TabsDirection} from '../../constants';
import {IWixStyleFont} from '@wix/wixstores-client-core/dist/es/src/types/wix-sdk';

export function geShowDividerFlagFromGlobalProps(props: ProvidedGlobalProps): boolean {
  return props.globals.style.styleParams.booleans.productPage_infoSectionShowDeviders;
}

export function geColumnNumberFromGlobalProps(props: ProvidedGlobalProps): number {
  return props.globals.style.styleParams.numbers.productPage_infoSectionColumnNumber;
}

export function getInfoSectionLayoutTypeFromGlobalProps(props: ProvidedGlobalProps): InfoSectionLayoutId {
  return props.globals.style.styleParams.numbers.productPage_infoSectionTypeId;
}

export function getAdditionalInfoFromGlobalProps(props: ProvidedGlobalProps): IProductAdditionalInfo[] {
  return props.globals.product.additionalInfo;
}

export function getOnActiveBIEventHandler(props: ProvidedGlobalProps) {
  return props.globals.infoSection.onActive;
}

export function geTabFontFromGlobalProps(props: ProvidedGlobalProps): IWixStyleFont {
  return (
    props.globals.style.styleParams.fonts.productPage_infoSectionTitleFontStyle ||
    props.globals.style.styleParams.fonts.quickView_infoSectionTitleFontStyle ||
    props.globals.style.styleParams.fonts.productPage_paragraphTitleFontStyle
  );
}

export function getIsRtlFromGlobalProps(props: ProvidedGlobalProps): boolean {
  return !!props.globals.isRTL;
}

export function getDirectionFromGlobalProps(props: ProvidedGlobalProps): TabsDirection {
  if (props.globals.style.styleParams.fonts.productPage_infoSectionAlignment) {
    if (
      props.globals.style.styleParams.fonts.productPage_infoSectionAlignment.value === 'left' ||
      props.globals.style.styleParams.fonts.productPage_infoSectionAlignment.value === 'center'
    ) {
      return TabsDirection.start;
    } else {
      return TabsDirection.end;
    }
  }
  return TabsDirection.start;
}
