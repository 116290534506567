import React from 'react';
import _ from 'lodash';
import SlickSlider, {Settings as SlickSettings} from 'react-slick';
import classNames from 'classnames';
import style from './ProductMediaNavigation.scss';
import {IMedia} from '../../../types/productDef';
import {
  getMediaUrl,
  isSantaFullWidthMode,
  convertConfigToNumber,
} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {ProductGalleryContext, IProductGalleryContext} from '../context';
import {Alignment, ArrowsDir} from '../../../constants';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/globalPropsProvider';
import {convertCssValueToConfig} from '../../../commons/utils';
import {ThumbnailsNavigation} from './ThumbnailsNavigation/ThumbnailsNavigation';
import {MediaNavigationItem} from './MediaNavigationItem/MediaNavigationItem';
import {DimensionsConfig} from '../../../types/app-types';

export interface IRefButtonWithIndex {
  index: number;
  ref: React.RefObject<HTMLButtonElement>;
}

export interface ProductMediaNavigationProps extends ProvidedGlobalProps {
  media: IMedia[];
  vertical: boolean;
  align: Alignment;
  layoutDimensions: DimensionsConfig;
  withDots: boolean;
}

export interface ProductMediaNavigationState {
  width: number;
  height: number;
  mountedDOM: boolean;
  currentActiveThumbnailIndex: number;
}

const THUMBNAIL_ITEM_DIMENSION_PX: number = convertCssValueToConfig(style.sharedStyleVariables_tumbnailsItemSize).num;
const THUMBNAIL_IMAGE_DIMENSION_PX: number = convertCssValueToConfig(style.sharedStyleVariables_tumbnailsSize).num;

const ThumbnailsPropsDefaultProps: Partial<ProductMediaNavigationProps> = {
  align: Alignment.CENTER,
};

@withGlobalProps
export class ProductMediaNavigation extends React.Component<ProductMediaNavigationProps, ProductMediaNavigationState> {
  private thumbnailsRefArray: IRefButtonWithIndex[] = [];
  private _ref: HTMLDivElement;
  private _slickRef: SlickSlider;

  constructor(props: ProductMediaNavigationProps) {
    super(props);
    this.state = this.getInitialState(props);
    this.setThumbnailsRefArray(props);
  }

  public componentDidMount() {
    const {clientHeight, clientWidth} = this._ref.parentElement;
    this.setState({
      height: clientHeight,
      width: clientWidth,
      mountedDOM: true,
    });
  }

  public componentWillReceiveProps(props) {
    this.setThumbnailsRefArray(props);
  }

  private readonly getRefFromThumbnailsRefArray = (index: number) => {
    return this.thumbnailsRefArray.find(refWithIndexObj => refWithIndexObj.index === index).ref;
  };

  private readonly setThumbnailsRefArray = props => {
    this.thumbnailsRefArray = props.media.reduce((acc, _item, index) => {
      acc.push({index, ref: React.createRef<HTMLButtonElement>()});
      return acc;
    }, []);
  };

  private readonly getInitialState = (props: ProductMediaNavigationProps): ProductMediaNavigationState => {
    const {
      globals: {
        dimensions: {height, width},
      },
      layoutDimensions: {widthConf, heightConf},
    } = props;
    return {
      width: widthConf ? convertConfigToNumber(widthConf, width) : 0,
      height: heightConf ? convertConfigToNumber(heightConf, height) : 0,
      mountedDOM: false,
      currentActiveThumbnailIndex: 0,
    };
  };

  private readonly getNavigationSpace = (): number => {
    const {vertical} = this.props;
    const {width, height} = this.state;
    if (isSantaFullWidthMode(this.props.globals)) {
      return vertical ? height : Math.min(980, width);
    } else {
      return vertical ? height : width;
    }
  };

  private readonly isWithNavigationArrows = () => {
    const {media, withDots} = this.props;
    if (withDots) {
      return false;
    } else {
      const space = this.getNavigationSpace();
      return space / THUMBNAIL_ITEM_DIMENSION_PX < media.length;
    }
  };

  private readonly getNumberOfSlidesToShow = (): number => {
    const {media} = this.props;
    const space = this.getNavigationSpace() - 50;
    return Math.min(Math.floor(space / THUMBNAIL_ITEM_DIMENSION_PX), media.length);
  };

  private getSlickSettings(): SlickSettings {
    const {vertical, withDots} = this.props;
    const slidesToShow = this.getNumberOfSlidesToShow();
    return {
      arrows: false,
      dots: false,
      infinite: false,
      slidesToShow: withDots ? 15 : slidesToShow + 1,
      slidesToScroll: withDots ? 15 : 1,
      speed: 400,
      accessibility: false,
      swipe: false,
      focusOnSelect: true,
      vertical: withDots ? false : vertical,
      variableWidth: withDots,
    };
  }

  private readonly getTargetThumbnailIndex = targetIndex => {
    const {media} = this.props;
    let nextIndex = targetIndex;
    if (targetIndex < 0) {
      nextIndex = media.length - 1;
    } else if (targetIndex === media.length) {
      nextIndex = 0;
    }
    return nextIndex;
  };

  private readonly focusOnTargetThumbnail = (targetIndex: number) => {
    const currentActiveThumbnailIndex = this.getTargetThumbnailIndex(targetIndex);
    const currentThumbnail = this.getRefFromThumbnailsRefArray(currentActiveThumbnailIndex);
    currentThumbnail.current.focus();
    this.setState({currentActiveThumbnailIndex});
  };

  private readonly smartSlickGoTo = (target: number) => {
    const {media} = this.props;
    const slidesToShow = this.getNumberOfSlidesToShow();
    const lastIndexToNavigate = media.length - slidesToShow;
    const next = _.inRange(target, lastIndexToNavigate, media.length) ? lastIndexToNavigate : target;
    this._slickRef.slickGoTo(next);
    this.focusOnTargetThumbnail(target);
  };

  private readonly getNavigationData = (
    target: ArrowsDir | number,
    currentIndex: number
  ): {slickPolicy: 'goTo' | 'next' | 'prev'; nextIndex: number} => {
    const {media} = this.props;
    if (_.isNumber(target)) {
      return {nextIndex: target, slickPolicy: 'goTo'};
    }
    const op = target === (ArrowsDir.LEFT || ArrowsDir.UP) ? _.subtract : _.add;
    const nextIndex = ((op(currentIndex, 1) % media.length) + media.length) % media.length;
    let slickPolicy;
    if (nextIndex === 0 || nextIndex === media.length - 1) {
      slickPolicy = 'goTo';
    } else if (nextIndex > currentIndex) {
      slickPolicy = 'next';
    } else {
      slickPolicy = 'prev';
    }
    return {nextIndex, slickPolicy};
  };

  public readonly getThumbnailOrDotItem = (
    mediaItem: IMedia,
    index: number,
    isSelected: boolean,
    onThumbnailClick: (index: number) => void
  ): JSX.Element => {
    const {vertical, withDots} = this.props;
    const dimensions = {
      width: THUMBNAIL_IMAGE_DIMENSION_PX,
      height: THUMBNAIL_IMAGE_DIMENSION_PX,
    };
    const imgUrl = getMediaUrl(mediaItem, dimensions, {isSSR: !this.state.mountedDOM});
    return (
      <MediaNavigationItem
        key={index}
        index={index}
        handleClick={onThumbnailClick}
        withDots={withDots}
        isSelected={isSelected}
        isVertical={vertical}
        imgUrl={imgUrl}
        mediaItem={mediaItem}
        ref={this.getRefFromThumbnailsRefArray(index) as any}
      />
    );
  };

  public getSlickSlider = (selectedIndex: number, withNavigationArrows: boolean, onNavigate) => {
    const {media, vertical} = this.props;
    const slideClasses = classNames(
      {[style.carouselWithVerticalNavigationArrows]: withNavigationArrows && vertical},
      {[style.carouselWithNavigationArrows]: withNavigationArrows}
    );
    return (
      <SlickSlider
        data-hook="thumbnails-slick"
        {...this.getSlickSettings()}
        className={slideClasses}
        ref={slider => (this._slickRef = slider)}>
        {_.map(media, (item, index) => {
          return this.getThumbnailOrDotItem(item, index, index === selectedIndex, onNavigate);
        })}
      </SlickSlider>
    );
  };

  public readonly getThumbnails = (
    {selectedIndex, changeSelected}: IProductGalleryContext,
    withNavigationArrows: boolean
  ): JSX.Element => {
    const {vertical} = this.props;

    const onNavigate = (target: ArrowsDir | number) => {
      const newTarget = this.getTargetThumbnailIndex(target);
      const {nextIndex, slickPolicy} = this.getNavigationData(newTarget, selectedIndex);

      if (slickPolicy === 'goTo') {
        this.smartSlickGoTo(nextIndex);
      } else if (slickPolicy === 'next') {
        this._slickRef.slickNext();
        this.focusOnTargetThumbnail(this.state.currentActiveThumbnailIndex + 1);
      } else if (slickPolicy === 'prev') {
        this._slickRef.slickPrev();
        this.focusOnTargetThumbnail(this.state.currentActiveThumbnailIndex - 1);
      }
      changeSelected(nextIndex);
    };
    return withNavigationArrows ? (
      <ThumbnailsNavigation onNavigation={onNavigate} vertical={vertical}>
        {this.getSlickSlider(selectedIndex, withNavigationArrows, onNavigate)}
      </ThumbnailsNavigation>
    ) : (
      <>{this.getSlickSlider(selectedIndex, withNavigationArrows, onNavigate)}</>
    );
  };

  public render() {
    const {media, vertical, align, withDots} = this.props;
    const {width, height} = this.state;
    const withNavigationArrows = this.isWithNavigationArrows();
    const thumbnailsClasses = classNames(
      style.root,
      {[style.vertical]: vertical},
      {[style.alignCenter]: align === Alignment.CENTER},
      {[style.withVerticalNavigationArrows]: withNavigationArrows && vertical},
      {[style.withNavigationArrows]: withNavigationArrows},
      {[style.withDots]: withDots}
    );
    const dynamicDimension = vertical
      ? {
          height: `${Math.min(media.length * THUMBNAIL_ITEM_DIMENSION_PX, height)}px`,
        }
      : {
          width: `${Math.min(
            media.length * THUMBNAIL_ITEM_DIMENSION_PX,
            isSantaFullWidthMode(this.props.globals) ? this.getNavigationSpace() : width
          )}px`,
        };
    return (
      <div data-hook="thumbnails" ref={r => (this._ref = r)} className={thumbnailsClasses} style={dynamicDimension}>
        <ProductGalleryContext.Consumer>
          {context => this.getThumbnails(context, withNavigationArrows)}
        </ProductGalleryContext.Consumer>
      </div>
    );
  }

  public static defaultProps = ThumbnailsPropsDefaultProps;
}
