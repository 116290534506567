import React from 'react';
import {IPropsInjectedByViewerScript, TranslationDictionary} from '../types/app-types';
import {translate} from '@wix/wixstores-client-core/dist/src/viewer-script/utils';
import {ISantaProps} from '@wix/native-components-infra/dist/src/types/types';

const PropsContext = React.createContext<ProvidedGlobalProps>(undefined);

export interface ProvidedGlobalProps {
  globals?: GlobalProps;
}

// tslint:disable max-union-size
export type GlobalProps = Pick<
  IPropsInjectedByViewerScript & ISantaProps,
  | 'appLoadBI'
  | 'dimensions'
  | 'experiments'
  | 'handleAddToCart'
  | 'handleUserInput'
  | 'hasMultipleMedia'
  | 'hideNavigationUrls'
  | 'infoSection'
  | 'isInteractive'
  | 'isDesktop'
  | 'isRTL'
  | 'isSSR'
  | 'navigate'
  | 'onFocusTriggered'
  | 'pagePath'
  | 'pagination'
  | 'product'
  | 'productUrl'
  | 'quantityRange'
  | 'selectedVariant'
  | 'shouldFocusAddToCartButton'
  | 'siteUrl'
  | 'socialSharing'
  | 'style'
  | 'texts'
  | 'updateLayout'
  | 'userInputErrors'
  | 'userInputs'
  | 'validate'
  | 'isMobile'
  | 'addedToCartSuccessfully'
>;
// tslint:enable max-union-size

export interface IProvidedTranslationProps {
  t?: TranslationFunction;
}

type TranslationFunction = (string, values?: {}, fallback?: string) => string;

export function withGlobalPropsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & ISantaProps) => {
    const {
      experiments,
      handleAddToCart,
      handleUserInput,
      hasMultipleMedia,
      hideNavigationUrls,
      infoSection,
      isInteractive,
      isDesktop,
      isRTL,
      isSSR,
      navigate,
      onFocusTriggered,
      pagePath,
      pagination,
      product,
      productUrl,
      quantityRange,
      selectedVariant,
      shouldFocusAddToCartButton,
      siteUrl,
      socialSharing,
      style,
      texts,
      userInputErrors,
      userInputs,
      validate,
      isMobile,
      addedToCartSuccessfully,
      ...passThroughProps
    } = props;

    const {appLoadBI, dimensions, updateLayout} = props.host;

    const globals = {
      appLoadBI,
      dimensions,
      experiments,
      handleAddToCart,
      handleUserInput,
      hasMultipleMedia,
      hideNavigationUrls,
      infoSection,
      isInteractive,
      isDesktop,
      isRTL,
      isSSR,
      navigate,
      onFocusTriggered,
      pagePath,
      pagination,
      product,
      productUrl,
      quantityRange,
      selectedVariant,
      shouldFocusAddToCartButton,
      siteUrl,
      socialSharing,
      style,
      texts,
      updateLayout,
      userInputErrors,
      userInputs,
      validate,
      isMobile,
      addedToCartSuccessfully,
    };

    return (
      <PropsContext.Provider value={{globals}}>
        <Component {...passThroughProps} />
      </PropsContext.Provider>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return props => {
    return <PropsContext.Consumer>{globalProps => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };
}

export function withDirection(WrappedComponent: React.ComponentType): React.FunctionComponent | any {
  return props => {
    return (
      <PropsContext.Consumer>
        {globalProps => {
          const isRTL = globalProps.globals.isRTL;
          const dir = isRTL ? 'rtl' : 'ltr';
          return (
            <div dir={dir}>
              <WrappedComponent {...props} />
            </div>
          );
        }}
      </PropsContext.Consumer>
    );
  };
}

/* Translations */

function getTranslation(translations: TranslationDictionary): TranslationFunction {
  return (translationKey: string, values: {} = {}, fallback: string = '') => {
    if (!translations[translationKey]) {
      return fallback;
    }
    return translate(translations[translationKey], values);
  };
}

export function withTranslations(Component: any): React.FunctionComponent | any {
  return props => {
    return (
      <PropsContext.Consumer>
        {globalProps => <Component {...props} t={getTranslation(globalProps.globals.texts)} />}
      </PropsContext.Consumer>
    );
  };
}
