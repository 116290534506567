import {IMedia, IProduct} from '../../types/productDef';
import {defaultProductMediaImage, SocialVendor} from '../../constants';
import {ProvidedGlobalProps} from '../../providers/globalPropsProvider';

export function getDefaultProductMedia(product: IProduct): IMedia {
  return product.media && product.media.length > 0 ? product.media[0] : defaultProductMediaImage;
}

export function shouldRenderVendorFromStyledParams(vendor: SocialVendor, props: ProvidedGlobalProps): boolean {
  const {
    globals: {
      style: {
        styleParams: {booleans},
      },
    },
  } = props;

  const styledKey = `productPage_socialNetwork${vendor}`;

  return booleans[styledKey];
}
