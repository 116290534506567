import * as React from 'react';
import {ImageMode} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import classNames from 'classnames';
import s from './ProductImage.scss';
import {IImageDimension, IMedia} from '../../../../types/productDef';
import {getMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';
import {ProductImage as Image} from '@wix/wixstores-client-common-components/dist/src/ProductImage/ProductImage';

export interface ProductImageItemProps extends ProvidedGlobalProps {
  mediaItem: IMedia;
  imageMode: ImageMode;
  withMagicZoom: boolean;
  productName: string;
  dimensions: IImageDimension;
  mountedToDOM: boolean;
  isSelected: boolean;
  imageLoaded(): any;
}

export interface ProductImageItemState {
  imageLoaded: boolean;
}
export enum DataHook {
  ProductImageItem = 'product-image-item',
  Image = 'product-image',
}

@withGlobalProps
export class ProductImage extends React.Component<ProductImageItemProps, ProductImageItemState> {
  public state = {imageLoaded: false};

  private readonly getMainImageUrl = (targetDimensions: {width: number; height: number}, isSSR = false): string => {
    const {imageMode, mediaItem} = this.props;
    return getMediaUrl(
      mediaItem,
      targetDimensions || mediaItem,
      {
        isSSR,
        imageMode,
      },
      true
    );
  };

  private readonly onImageLoad = () => {
    const {imageLoaded} = this.props;
    imageLoaded();
    this.setState({imageLoaded: true});
  };

  private readonly getImageContainerStyle = () => {
    const {imageLoaded} = this.state;
    return imageLoaded
      ? {}
      : {
          backgroundImage: `url(${this.getMainImageUrl(null, true)})`,
        };
  };

  public render() {
    const {imageMode, mountedToDOM, isSelected, withMagicZoom, mediaItem, dimensions, productName} = this.props;

    const imageClass = classNames([
      s.media,
      {
        [s.selected]: isSelected,
        [s.mediaWithZoom]: withMagicZoom,
      },
    ]);

    return (
      <Image
        imageMode={imageMode as any}
        productName={productName}
        mountedToDOM={mountedToDOM}
        imageLoaded={this.onImageLoad}
        style={this.getImageContainerStyle()}
        className={imageClass}
        containerDataHook={DataHook.ProductImageItem}
        imgDataHook={DataHook.Image}
        altText={mediaItem.altText}
        src={this.getMainImageUrl(dimensions)}
      />
    );
  }
}
