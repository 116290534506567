import React from 'react';
import '../slick-carousel.global.scss';
import s from './ProductGallery.scss';
import classNames from 'classnames';
import {ProvidedGlobalProps} from '../../../providers/globalPropsProvider';
import {IMedia} from '../../../types/productDef';
import {getMainImageRatio} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {ProductGalleryContext, IProductGalleryContext} from '../context';
import SlickSlider from 'react-slick';
import {IVideoPlayer} from '../../../types/app-types';

export interface ProductGalleryProps extends ProvidedGlobalProps {
  imageRatioId: number;
  media: IMedia;
  withImageRatio: boolean;
  mediaSignature?: string;
}

interface ProductGalleryState {
  selected: number;
  mainMediaSlider: SlickSlider;
}

const ProductGalleryDefaultProps: Partial<ProductGalleryProps> = {
  withImageRatio: false,
};

export class ProductGallery extends React.Component<ProductGalleryProps, ProductGalleryState> {
  public state = {
    selected: 0,
    mainMediaSlider: null,
  };

  private readonly videoPlayers: Record<number, IVideoPlayer> = {};

  private getImageRationClass(media: IMedia, imageRatioId: number) {
    const ratioClassSuffix = getMainImageRatio(media, imageRatioId).title.replace(':', '-');
    return `image-ratio-${ratioClassSuffix}`;
  }

  public createCompositeContext(): IProductGalleryContext {
    const {mainMediaSlider, selected} = this.state;
    const changeSelected = (newlySelectedIndex: number) => {
      if (this.videoPlayers[selected]) {
        this.videoPlayers[selected].pause();
      }
      this.setState({selected: newlySelectedIndex});
      mainMediaSlider.slickGoTo(newlySelectedIndex);
      if (this.videoPlayers[newlySelectedIndex]) {
        this.videoPlayers[newlySelectedIndex].play();
      }
    };
    const registerVideoPlayer = (player: IVideoPlayer, index: number) => {
      this.videoPlayers[index] = player;
    };
    const initProductGalleryContext = (mainMediaRef: SlickSlider) => {
      if (mainMediaRef && mainMediaSlider !== mainMediaRef) {
        this.setState({mainMediaSlider: mainMediaRef});
      }
    };
    return {
      registerVideoPlayer,
      selectedIndex: selected,
      changeSelected,
      initProductGalleryContext,
      mainMediaSlider,
    };
  }

  private getComposite(): JSX.Element {
    const {children, withImageRatio, media, imageRatioId} = this.props;
    const imageRatio = this.getImageRationClass(media, imageRatioId);
    const productGalleryComposite = classNames(
      {[s.productGallery]: withImageRatio},
      {[s.withImageRatio]: withImageRatio},
      {[s[imageRatio]]: withImageRatio},
      {[s.productGallery]: !withImageRatio}
    );

    return (
      <div data-hook="product-gallery-composite" className={productGalleryComposite}>
        {children}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ProductGalleryProps): void {
    if (nextProps.mediaSignature !== this.props.mediaSignature) {
      this.createCompositeContext().changeSelected(0);
    }
  }

  public render() {
    const {media} = this.props;
    return media ? (
      <ProductGalleryContext.Provider value={this.createCompositeContext()}>
        {this.getComposite()}
      </ProductGalleryContext.Provider>
    ) : (
      this.getComposite()
    );
  }

  public static defaultProps = ProductGalleryDefaultProps;
}
