import React from 'react';
import {IProductOptionsItem, IProductOptionSelectionItem} from '../../../types/productDef';
import s from './ProductColor.scss';
import {ColorSampleGroup} from '@wix/wixstores-client-common-components/dist/src/ColorSampleGroup/ColorSampleGroup';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/globalPropsProvider';

export interface ProductColorsProps {
  options: IProductOptionsItem;
  allowMultiple?: boolean;
  error?: JSX.Element;
  onSelect?: Function;
  selected?: IProductOptionSelectionItem[];
}

@withGlobalProps
export class ProductColors extends React.Component<ProductColorsProps & ProvidedGlobalProps> {
  public static defaultProps = {
    allowMultiple: false,
    selected: [],
  };

  public readonly onSelect = (selected: IProductOptionSelectionItem[]): void => {
    if (this.props.onSelect) {
      this.props.onSelect(selected);
    }
  };

  public readonly renderDescription = (): string => {
    const {selected} = this.props;

    if (!selected.length) {
      return null;
    }

    return `: ${selected.map((selection: IProductOptionSelectionItem) => selection.description).join(', ')}`;
  };

  private renderError() {
    const {error} = this.props;

    if (!error) {
      return null;
    }

    return error;
  }

  public render() {
    const {
      options: {title, selections},
      allowMultiple,
      selected,
      globals: {
        isRTL,
        experiments: {isNewMobileLayout},
      },
    } = this.props;

    return (
      <section>
        <div data-hook="product-colors-title" className={s.description}>
          {title}
          {this.renderDescription()}
        </div>
        <div className={s.actionable}>
          {!isRTL && this.renderError()}
          {isNewMobileLayout ? (
            <ColorSampleGroup
              allowMultiple={allowMultiple}
              onSelect={this.onSelect}
              options={selections}
              selected={selected}
              colorSampleClassName={s.colorSampler}
            />
          ) : (
            <ColorSampleGroup
              allowMultiple={allowMultiple}
              onSelect={this.onSelect}
              options={selections}
              selected={selected}
            />
          )}
          {isRTL && this.renderError()}
        </div>
      </section>
    );
  }
}
