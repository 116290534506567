import {withSentryErrorBoundary} from '@wix/native-components-infra';
import {bootstrapApp} from '../../commons/bootstrap';
import {ProductPageApp} from './ProductPageApp';
import {PRODUCT_PAGE_DSN} from '@wix/wixstores-client-core/dist/es/src/viewer-script/sentryConf';

export const component = withSentryErrorBoundary(
  bootstrapApp(ProductPageApp, {
    cssPath: ['productPage.min.css', 'productPage.stylable.bundle.css'],
  }),
  {
    dsn: PRODUCT_PAGE_DSN,
    config: {environment: 'Native Component'},
  }
);
